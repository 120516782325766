import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import get from 'lodash/get';
import AdSense from 'react-adsense';

import DrinksList from '../components/DrinksList';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  Pagination,
  Icon,
  SegmentInline,
  Segment,
  Grid,
} from 'semantic-ui-react';

import PopularDrinks from '../components/PopularDrinks';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMongodbCocktailmeisterDrinks(limit: 60, sort: { fields: image }) {
        edges {
          node {
            id
            name
            slug
            ingredients {
              _0
              _1
            }
            instructions
            category
            alcoholic
            color
            color1
            glass
            occasions
            skill
            tastes
            tools
            image
          }
        }
      }
    }
  `);

  const drinks = get(data, 'allMongodbCocktailmeisterDrinks.edges');
  return (
    <Layout>
      <SEO />
      <PopularDrinks></PopularDrinks>
      <h3>All Drinks</h3>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <p>
              All 5500+ drinks recipes from around the world. Very easy to mix
              at home, instructions are step by step explained with all the
              right amount of ingredients.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <AdSense.Google
              client="ca-pub-5019475886479300"
              slot="7426418207"
              responsive="true"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DrinksList drinks={drinks} />
      <Segment textAlign="center" className="pagination-nav">
        <Pagination
          defaultActivePage={1}
          totalPages={200}
          firstItem={null}
          lastItem={null}
          boundaryRange={0}
          siblingRange={0}
          prevItem={null}
          ellipsisItem={null}
          nextItem={{
            as: SegmentInline,
            content: (
              <Link to="/all-drinks/2">
                <Icon name="angle right" />
              </Link>
            ),
            icon: true,
          }}
        />
      </Segment>
    </Layout>
  );
};

export default IndexPage;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import DrinksList from './DrinksList';
import { Grid } from 'semantic-ui-react';
import AdSense from 'react-adsense';

function PopularDrinks() {
  return (
    <StaticQuery
      query={graphql`
        {
          allMongodbCocktailmeisterDrinks(
            limit: 24
            filter: { tags: { eq: "popular" } }
            sort: { fields: image }
          ) {
            edges {
              node {
                id
                name
                slug
                ingredients {
                  _0
                }
                category
                alcoholic
                glass
                skill
                image
              }
            }
          }
        }
      `}
      render={(data) => {
        const drinks = get(data, 'allMongodbCocktailmeisterDrinks.edges');
        return (
          <div>
            <h3>Popular Drinks</h3>
            <p>
              Hand picked top 8 drinks recipes that are very popular and
              trending around the world. Very easy to mix at home, instructions
              are step by step explained with all the right amount of
              ingredients.
            </p>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <AdSense.Google
                    client="ca-pub-5019475886479300"
                    slot="1622114101"
                    responsive="true"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <DrinksList drinks={drinks}></DrinksList>
          </div>
        );
      }}
    ></StaticQuery>
  );
}

export default PopularDrinks;
